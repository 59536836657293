import React from 'react';
import './App.scss';
import StatusCircle, {DeliveryStates} from './components/delivery-status-circles';

function App() {
  return (
    <div className='App'>
      <StatusCircle status={DeliveryStates.sendAnnounced} deliveryDate='' deliveryStatusTitle='Sendung angekündigt' />
      <StatusCircle status={DeliveryStates.acceptance} deliveryDate='25–27. Juni' deliveryStatusTitle='Annahme' />
      <StatusCircle status={DeliveryStates.distribution} deliveryDate='Morgen' deliveryStatusTitle='Verteilung' />
      <StatusCircle status={DeliveryStates.delivery} deliveryDate='Heute' deliveryStatusTitle='Zustellung' />
      <StatusCircle status={DeliveryStates.return} deliveryDate='Heute' deliveryStatusTitle='Rücksendung' />
      <StatusCircle status={DeliveryStates.deliveryCompleted} deliveryDate='30. Juni' deliveryStatusTitle='Zustellung'/>
      <StatusCircle status={DeliveryStates.notMet} deliveryDate='Heute' deliveryStatusTitle='Nicht angetroffen' />
      <StatusCircle status={DeliveryStates.readyForPickup} deliveryDate='Heute' deliveryStatusTitle='Abholbereit' />
    </div>
  );
}

export default App;
