import React from 'react';
import BasicCircle from './basic-circle';
import BouncingTruck from './circle-components/bouncing-truck';
import IconHome from './circle-components/icon-home';
import IDeliveryStatusCircle from './IDeliveryStatusCircle';
import styles from './rejected-truck-circle.module.scss';

const RejectedTruckCircle: React.FC<IDeliveryStatusCircle> = (props) => (
  <BasicCircle {...props} >
    <IconHome />
    <div className={styles.returnTruckWrapper} style={{animation: `${styles.returnAnimation} 1s linear forwards`}}>
      <BouncingTruck direction='left' />
    </div>
  </BasicCircle>
);

export default RejectedTruckCircle;
