import React from 'react';
import PackageIcon from './assets/package.svg';
import PostOfficeIcon from './assets/postoffice.svg';
import BasicCircle from './basic-circle';
import IDeliveryStatusCircle from './IDeliveryStatusCircle';
import styles from './start-delivery-circle.module.scss';

const StartDeliveryCircle: React.FC<IDeliveryStatusCircle>  = (props) => {
  return (
    <BasicCircle {...props}>
      <div className={styles.iconPackage} style={{backgroundImage: `url(${PackageIcon})`}}  />
      <div className={styles.iconPostOffice} style={{backgroundImage: `url(${PostOfficeIcon})`}} />
    </BasicCircle>
  );
};

export default StartDeliveryCircle;
