import React from 'react';
import BasicCircle from './basic-circle';
import BouncingTruck from './circle-components/bouncing-truck';
import IconHome from './circle-components/icon-home';
import IDeliveryStatusCircle from './IDeliveryStatusCircle';
import styles from './incomming-truck-circle.module.scss';

const IncommingTruckCircle: React.FC<IDeliveryStatusCircle> = (props) => (
  <BasicCircle {...props} >
    <IconHome />
    <div
      className={styles.incommingTruckWrapper}
      style={{animation: `${styles.incommingAnimation} 1s linear forwards`}}
    >
      <BouncingTruck direction='right'/>
    </div>
  </BasicCircle>
);

export default IncommingTruckCircle;
