import React from 'react';
import ParcelLockerIcon from './assets/parcel-locker.svg';
import PostmanIcon from './assets/postman.svg';
import IncommingTruckCircle from './incomming-truck-circle';
import RejectedTruckCircle from './rejected-truck-circle';
import StartDeliveryCircle from './start-delivery-circle';
import StaticIconCircle from './static-icon-circle';

export enum DeliveryStates {
  sendAnnounced,
  acceptance,
  distribution,
  delivery,
  return,
  deliveryCompleted,
  notMet,
  readyForPickup
}

interface IDeliveryData {
  deliveryDate: string;
  deliveryStatusTitle: string;
}

const StatusMapping = {
  [DeliveryStates.sendAnnounced]: ({deliveryDate, deliveryStatusTitle}: IDeliveryData) =>
    <StartDeliveryCircle
      strokeColor='#FFDD00'
      groundColor='#666'
      deliveryStatusTitle={deliveryStatusTitle}
      deliveryDate={deliveryDate}
      deliveryStep={1}
    />,
  [DeliveryStates.acceptance]: ({deliveryDate, deliveryStatusTitle}: IDeliveryData) =>
    <IncommingTruckCircle
      strokeColor='#FFDD00'
      groundColor='#000'
      deliveryStatusTitle={deliveryStatusTitle}
      deliveryDate={deliveryDate}
      deliveryStep={2}
    />,
  [DeliveryStates.distribution]: ({deliveryDate, deliveryStatusTitle}: IDeliveryData) =>
    <IncommingTruckCircle
      strokeColor='#FFDD00'
      groundColor='#000'
      deliveryStatusTitle={deliveryStatusTitle}
      deliveryDate={deliveryDate}
      deliveryStep={3}
    />,
  [DeliveryStates.delivery]: ({deliveryDate, deliveryStatusTitle}: IDeliveryData) =>
    <IncommingTruckCircle
      strokeColor='#FFDD00'
      groundColor='#000'
      deliveryStatusTitle={deliveryStatusTitle}
      deliveryDate={deliveryDate}
      deliveryStep={4}
    />,
  [DeliveryStates.return]: ({deliveryDate, deliveryStatusTitle}: IDeliveryData) =>
    <RejectedTruckCircle
      strokeColor='#D20019'
      groundColor='#000'
      deliveryStatusTitle={deliveryStatusTitle}
      deliveryDate={deliveryDate}
      deliveryStep={5}
    />,
  [DeliveryStates.deliveryCompleted]: ({deliveryDate, deliveryStatusTitle}: IDeliveryData) =>
    <IncommingTruckCircle
      strokeColor='#FFDD00'
      groundColor='#2D8700'
      deliveryStatusTitle={deliveryStatusTitle}
      deliveryDate={deliveryDate}
      deliveryStep={5}
    />,
  [DeliveryStates.notMet]: ({deliveryDate, deliveryStatusTitle}: IDeliveryData) =>
    <StaticIconCircle
      strokeColor='#FFDD00'
      groundColor='#000'
      deliveryStatusTitle={deliveryStatusTitle}
      deliveryDate={deliveryDate}
      deliveryStep={4}
      icon={PostmanIcon}
    />,
  [DeliveryStates.readyForPickup]: ({deliveryDate, deliveryStatusTitle}: IDeliveryData) =>
    <StaticIconCircle
      strokeColor='#FFDD00'
      groundColor='#000'
      deliveryStatusTitle={deliveryStatusTitle}
      deliveryDate={deliveryDate}
      deliveryStep={4}
      icon={ParcelLockerIcon}
    />
};

const StatusCircle: React.FC<{
  status: DeliveryStates,
  deliveryDate: string,
  deliveryStatusTitle: string
}> = ({status, deliveryDate, deliveryStatusTitle}) => {
  const ChosenCircle = StatusMapping[status];
  return <ChosenCircle deliveryDate={deliveryDate} deliveryStatusTitle={deliveryStatusTitle}/>;
};

export default StatusCircle;
