import React from 'react';
import styles from './basic-circle.module.scss';
import IDeliveryStatusCircle, { DeliveryStep } from './IDeliveryStatusCircle';

const stepsRadius = [647.168, 517.301, 317.301, 117.301, 1];
const showStaticProgres = (deliveryStep: DeliveryStep) => `${stepsRadius[deliveryStep - 1]}`;
const showAnimation = (deliveryStep: DeliveryStep) => `${styles['step-' + deliveryStep]} 1s forwards`;

function displayProgress(deliveryStep: DeliveryStep) {

  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');

  if (deliveryStep === 1) {
    return {display: 'none'};
  }

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
    return {strokeDashoffset: showStaticProgres(deliveryStep)};
  }

  return {animation: showAnimation(deliveryStep)};
}

const DeliveryStatusCircle: React.FC<IDeliveryStatusCircle> = ({
  strokeColor,
  groundColor,
  deliveryStatusTitle,
  deliveryDate,
  deliveryStep,
  children
}) => (
  <div className={styles.circleWrapper}>
    <div className={styles.ground} style={{backgroundColor: groundColor}} />
    <div className={styles.deliveryStatusTitle}>{deliveryStatusTitle}</div>
    <div className={styles.deliveryDate}>{deliveryDate}</div>
    {children}
    <svg className={styles.progress} width='220' height='220' viewBox='0 0 220 220'>
      <circle cx='110' cy='110' r='103' fill='none' stroke='#D8D8D8' strokeWidth='14' />
      <circle
        className={styles.progress__value}
        cx='110' cy='110' r='103' fill='none'
        stroke={strokeColor} strokeWidth='14'
        style={displayProgress(deliveryStep)}
      />
    </svg>
  </div>
);

export default DeliveryStatusCircle;
