import React from 'react';
import BasicCircle from './basic-circle';
import IDeliveryStatusCircle from './IDeliveryStatusCircle';
import styles from './static-icon-circle.module.scss';

interface IStaticIconCircle extends IDeliveryStatusCircle {
  icon: string;
}

const StaticIconCircle: React.FC<IStaticIconCircle> = ({icon, ...props}) => {
  return (
    <BasicCircle {...props}>
      <div className={styles.icon} style={{backgroundImage: `url(${icon})`}} />
    </BasicCircle>
  );
};

export default StaticIconCircle;
